// Global styling for this template
body {
    font-size: 16px;
    color: $gray-900;
}

p {
    line-height: 1.6;
    margin: 20px 0;

    a {
        text-decoration: underline;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 800;
}

a {
    color: $gray-900;

    &:focus,
    &:hover {
        color: $primary;
    }
}

blockquote {
    font-style: italic;
    color: $gray-800;
    border-left: 2px solid $teal;
    margin: 1.5em 0px;
    padding: 0.5em 10px;
    background: #f9f9f9;
}

.section-heading {
    font-size: 36px;
    font-weight: 700;
    margin-top: 60px;
}

.caption {
    font-size: 14px;
    font-style: italic;
    display: block;
    margin: 0;
    padding: 10px;
    text-align: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

::-moz-selection {
    color: $white;
    background: $primary;
    text-shadow: none;
}

::selection {
    color: $white;
    background: $primary;
    text-shadow: none;
}

img::selection {
    color: $white;
    background: transparent;
}

img::-moz-selection {
    color: $white;
    background: transparent;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.input-container {
    margin-bottom: 20px;
}

.settings-container {
    margin-bottom: 20px;
}

.setting {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
}

select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
}

textarea {
    width: 100%;
    min-height: 120px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: monospace;
    font-size: 16px;
    resize: vertical;
}

.help-text {
    margin-top: 8px;
    font-size: 14px;
    color: #666;
}

button {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
}

button:hover {
    background-color: #2980b9;
}

#status-message {
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
}

#download-container {
    margin-top: 20px;
    text-align: center;
}

#download-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #2ecc71;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 600;
    transition: background-color 0.2s;
}

#download-link:hover {
    background-color: #27ae60;
}

.hidden {
    display: none;
}

.error {
    color: #e74c3c;
}